import "core-js/modules/es6.regexp.to-string";
import axios from 'axios';
import router from '@/router/routers';
import { Notification, MessageBox } from 'element-ui';
import store from "../store";
import { getToken } from '@/utils/auth';
import Config from '@/settings';

// 创建axios实例
var service = axios.create({
  // baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '/', // api 的 base_url
  baseURL: process.env.VUE_APP_BASE_API,
  // api 的 base_url
  timeout: Config.timeout // 请求超时时间
});

// request拦截器
service.interceptors.request.use(function (config) {
  if (getToken()) {
    config.headers['Authorization'] = getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
  }

  config.headers['Content-Type'] = 'application/json';
  return config;
}, function (error) {
  // Do something with request error
  console.log(error); // for debug
  Promise.reject(error);
});

// response 拦截器
service.interceptors.response.use(function (response) {
  var code = response.status;
  if (code < 200 || code > 300) {
    Notification.error({
      title: response.message
    });
    return Promise.reject('error');
  } else {
    var _ref = response.data || {},
      exceptionCode = _ref.exceptionCode,
      exceptionMsg = _ref.exceptionMsg;

    // 业务异常
    if (exceptionCode && exceptionCode !== '200') {
      if (exceptionMsg) {
        Notification.warning({
          title: exceptionMsg
        });
      }
      return Promise.reject('error');
    }
    return response.data;
  }
}, function (error) {
  var code = 0;
  try {
    code = error.response.data.status;
  } catch (e) {
    if (error.toString().indexOf('Error: timeout') !== -1) {
      Notification.error({
        title: '网络请求超时',
        duration: 5000
      });
      return Promise.reject(error);
    }
  }
  if (code) {
    if (code === 401) {
      MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        store.dispatch('LogOut').then(function () {
          location.reload(); // 为了重新实例化vue-router对象 避免bug
        });
      });
    } else if (code === 403) {
      router.push({
        path: '/401'
      });
    } else {
      var errorMsg = error.response.data.message;
      if (errorMsg !== undefined) {
        Notification.error({
          title: errorMsg,
          duration: 5000
        });
      }
    }
  } else {
    Notification.error({
      title: '接口请求失败',
      duration: 5000
    });
  }
  return Promise.reject(error);
});
export default service;