var baseUrl = process.env.VUE_APP_BASE_API;
var api = {
  state: {
    // baseUrl，
    baseApi: baseUrl,
    // 部署包上传
    deployUploadApi: baseUrl + '/api/deploy/upload',
    // SQL脚本上传
    databaseUploadApi: baseUrl + '/api/database/upload',
    // 实时控制台
    socketApi: baseUrl + '/websocket?token=kl',
    // 图片上传
    imagesUploadApi: baseUrl + '/api/pictures',
    // 修改头像
    updateAvatarApi: baseUrl + '/api/users/updateAvatar',
    // 上传文件到七牛云
    qiNiuUploadApi: baseUrl + '/api/qiNiuContent',
    uploadApi: baseUrl + '/api/upload',
    // Sql 监控
    sqlApi: baseUrl + '/druid',
    // swagger
    swaggerApi: baseUrl + '/doc.html',
    // 文件上传
    fileUploadApi: baseUrl + '/api/localStorage',
    // 文件批量上传
    fileMultiUploadApi: baseUrl + '/api/localStorage/batch',
    // 通过ID查询文件信息
    // url: api/localStorage/ids/{ids}
    // method: post
    // response: [{ id, name, realName, path }]
    fileInfoApi: baseUrl + 'api/localStorage/ids'
  }
};
export default api;