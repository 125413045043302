import Vue from 'vue';
import Router from 'vue-router';
import Layout from "../layout/index";
Vue.use(Router);
export var constantRouterMap = [{
  path: '/login',
  meta: {
    title: '登录',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/login'], resolve);
  },
  hidden: true
}, {
  path: '/404',
  component: function component(resolve) {
    return require(['@/views/features/404'], resolve);
  },
  hidden: true
}, {
  path: '/401',
  component: function component(resolve) {
    return require(['@/views/features/401'], resolve);
  },
  hidden: true
}, {
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component(resolve) {
      return require(['@/views/features/redirect'], resolve);
    }
  }]
}, {
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component(resolve) {
      return require(['@/views/home'], resolve);
    },
    name: 'Dashboard',
    meta: {
      title: '首页',
      icon: 'index',
      affix: true,
      noCache: true
    }
  }]
}, {
  path: '/user',
  component: Layout,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'center',
    component: function component(resolve) {
      return require(['@/views/system/user/center'], resolve);
    },
    name: '个人中心',
    meta: {
      title: '个人中心'
    }
  }]
}, {
  path: '/yshop/materia/index',
  name: "images",
  meta: {
    title: '上传图片',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/components/editorMaterial/index'], resolve);
  },
  hidden: true
}];
export default new Router({
  mode: 'history',
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRouterMap
});