import "core-js/modules/es6.regexp.replace";
import _objectSpread from "/var/jenkins_home/workspace/PRO_md_anu-admin-qd-hospital/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import Breadcrumb from '@/components/Breadcrumb';
import Hamburger from '@/components/Hamburger';
import Screenfull from '@/components/Screenfull';
import Search from '@/components/HeaderSearch';
import Avatar from '@/assets/images/avatar.png';
export default {
  components: {
    Breadcrumb: Breadcrumb,
    Hamburger: Hamburger,
    Screenfull: Screenfull,
    Search: Search
  },
  data: function data() {
    return {
      Avatar: Avatar,
      dialogVisible: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['sidebar', 'device', 'user', 'baseApi'])), {}, {
    show: {
      get: function get() {
        return this.$store.state.settings.showSettings;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'showSettings',
          value: val
        });
      }
    },
    userSchool: {
      get: function get() {
        var schoolNameList = this.user.schoolNameList;
        if (!schoolNameList || schoolNameList.length !== 1) {
          return null;
        }
        return schoolNameList.join(',');
      }
    }
  }),
  methods: {
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    open: function open() {
      var _this = this;
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this.logout();
      });
    },
    logout: function logout() {
      this.$router.replace({
        path: '/'
      });
      this.$store.dispatch('LogOut').then(function () {
        location.reload();
      });
    }
  }
};