//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LogoIcon from '@/assets/images/logo_icon.png';
import Logo from '@/assets/images/logo.png';
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: '后台管理',
      logo: Logo,
      LogoIcon: LogoIcon
    };
  }
};