import "/var/jenkins_home/workspace/PRO_md_anu-admin-qd-hospital/node_modules/core-js/modules/es6.array.iterator.js";
import "/var/jenkins_home/workspace/PRO_md_anu-admin-qd-hospital/node_modules/core-js/modules/es6.promise.js";
import "/var/jenkins_home/workspace/PRO_md_anu-admin-qd-hospital/node_modules/core-js/modules/es6.object.assign.js";
import "/var/jenkins_home/workspace/PRO_md_anu-admin-qd-hospital/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css';
import Element from 'element-ui';
import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';

// 数据字典
import dict from "./components/Dict";

// 权限指令
import permission from "./components/Permission";
import "./assets/styles/element-variables.scss";
// global css
import "./assets/styles/index.scss";

// 代码高亮
import VueHighlightJS from 'vue-highlightjs';
import 'highlight.js/styles/atom-one-dark.css';

// 百度地图
import BaiduMap from 'vue-baidu-map';
import App from "./App";
import store from "./store";
import router from "./router/routers";
import "./assets/icons"; // icon
import "./router/index"; // permission control

import * as constants from "./constants";
Vue.use(VueHighlightJS);
Vue.use(mavonEditor);
Vue.use(permission);
Vue.use(dict);
Vue.use(Element, {
  size: Cookies.get('size') || 'small' // set element-ui default size
});

Vue.use(BaiduMap, {
  // 百度地图API KEY
  ak: constants.VUE_APP_BAIDU_MAP_API_KEY
});
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});